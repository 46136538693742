jQuery( () => {

  let lazyVideos, lazyVideoObserver, source, videoSource;
  lazyVideos = [].slice.call( document.querySelectorAll( "video.lazy" ) );

  if ( "IntersectionObserver" in window ) {
    
    lazyVideoObserver = new IntersectionObserver( function( entries ) {
      entries.forEach( function( video ) {
        if ( video.isIntersecting ) {
          for ( source in video.target.children ) {
            if ( ! Object.prototype.hasOwnProperty.call( video.target.children, source ) ) {
              continue;
            }
            videoSource = video.target.children[source];
            if ( typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE" ) {
              videoSource.src = videoSource.dataset.src;
            }
          }

          video.target.load();
          video.target.classList.remove( "lazy" );
          lazyVideoObserver.unobserve( video.target );
        }
      });
    });

    lazyVideos.forEach( function( lazyVideo ) {
      lazyVideoObserver.observe( lazyVideo );
    });
  }

});
